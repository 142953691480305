<template>
  <div class="setBox">
    <NavBar title="设置"></NavBar>
    <div class="contentBox">
      <ListBox title="关于我们" :right-icon="true" @click.native="$router.push('/other/aboutUs')"></ListBox>
      <ListBox title="用户协议" :right-icon="true" @click.native="$router.push('/other/userAgreement')"></ListBox>
      <ListBox title="当前版本" :tips="version + ''" @click.native="checkVersion"></ListBox>
      <Space :height="601"></Space>
      <van-button block type="primary" v-if="$isLogin()" @click="loginOut">退出登录</van-button>
    </div>
  </div>
</template>
<script>
let consoleTime = '';
let consoleObj = '';
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {
      consoleClick: 0,
      version: 0,
    };
  },
  created() {
    this.$http('get', '/v1/version').then(res => {
      this.version = res.data.pageVersion;
    });
  },
  // mounted() {},
  methods: {
    checkVersion() {
      this.$store.commit('save', {
        checkVersionState: this.$store.state.checkVersionState + 1,
      });
    },
    touchStart() {
      clearTimeout(consoleTime);
      this.consoleClick++;
      if (this.consoleClick >= 5) {
        if (consoleObj) {
          consoleObj && consoleObj.destroy();
          consoleObj = null;
        } else {
          this.vConsole();
        }
      }
      consoleTime = setTimeout(() => {
        this.consoleClick = 0;
      }, 500);
    },
    vConsole() {
      let script = document.createElement('script');
      // script.src = 'https://cdn.bootcss.com/vConsole/3.3.0/vconsole.min.js';
      script.src = 'https:////cdn.bootcss.com/eruda/1.5.2/eruda.min.js';
      script.onload = () => {
        // consoleObj = new VConsole();
        consoleObj = window.eruda.init();
      };
      document.body.append(script);
    },
    loginOut() {
      this.$logout();
      this.$router.replace('/account/login');
    },
  },
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
